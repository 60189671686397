//VENDOR
@import "../../bower_components/normalize-css/normalize.css";
@import "../../bower_components/uikit/less/uikit.less";

//MY APP
@import "partials/var.less"; // Переменные для сайта
@import "partials/fonts.less"; // Шрифты
@import "partials/init.less"; // Базовые стили
@import "partials/grid.less"; // Семантическая сетка.

@import "vars.less"; //Переменные

@import "partials/app.less";
@import "partials/owl.carousel.less";
@import "partials/pickadate.less";
@import "partials/jquery.fancybox.less";
@import "overall.less"; // Основные стили сайта
@import "responsive.less"; //Стили для устройств
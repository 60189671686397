@font-face{
    font-family:@f1;
    src: url('../fonts/MuseoSansCyrl-100.otf');
}
@font-face{
    font-family:@f3;
    src: url('../fonts/MuseoSansCyrl-300.otf');
}
@font-face{
    font-family:@f5;
    src: url('../fonts/MuseoSansCyrl-500.otf');
}
@font-face{
    font-family:@f7;
    src: url('../fonts/MuseoSansCyrl-700.otf');
}
body{
    color:#333333;
    font-family: @f3;
}
a{
    color:#4291c1;
    text-decoration: underline;
    &:hover{
        text-decoration: none;
    }
}
.inner{
    width:1310px;
    margin: 0 auto;
}
table,tr,td{
    border:0;
    border-spacing: 0 !important;
}
.f3{font-family: @f3}
.f5{font-family: @f5}
h1, h2, h3, h4, h5{
    font-family: @f3;
}
.float-left{
    float:left;
}
.no-bg{background:none !important; }
.align-left{
    text-align: left !important;
}
input, textarea{
    &::-webkit-input-placeholder       {transition: all 0.3s;}
    &::-moz-placeholder                {transition: all 0.3s;}
    &:-moz-placeholder                 {transition: all 0.3s;}
    &:-ms-input-placeholder            {transition: all 0.3s;}
    &:focus::-webkit-input-placeholder {opacity:0; transition: all 0.5s;}
    &:focus::-moz-placeholder          {opacity:0; transition: all 0.5s;}
    &:focus:-moz-placeholder           {opacity:0; transition: all 0.5s;}
    &:focus:-ms-input-placeholder      {opacity:0; transition: all 0.5s;}
}
input.dateinput{
    &::-webkit-input-placeholder       {opacity:1 !important}
    &::-moz-placeholder                {opacity:1 !important}
    &:-moz-placeholder                 {opacity:1 !important}
    &:-ms-input-placeholder            {opacity:1 !important}
}
body{
    transition: all 0.5s;
    position: absolute;
    top:0;
    left:0;
}
.header{
    background:rgba(0,0,0,0.84);
    position: absolute;
    left:0;
    right:0;
    z-index: 3;
    font-family: @f1;
    a{
        text-decoration: none;
    }
    .logo{
        float: left;
        margin-top: 10px;
        margin-bottom: 9px;
    }
    .menu{
        margin:0;
        padding:0;
        margin-left:5px;
        list-style: none;
        li{
            margin-left:20px;
            float:left;
            a{
                color: #fff;
                padding: 12px 0;
                display: block;
                font-size:13px;
                transition:all .3s;
                &:hover, .active{
                    color:@orange;
                    text-decoration: none;
                }
            }
        }
    }
    .b-btn{
        float:right;
        margin-top: 7px;
    }
    .feedback{
        color: #fff;
        float: right;
        margin-right: 25px;
        cursor: pointer;
        margin-top: 13px;
        text-decoration: underline;
        &:hover{
            text-decoration: none;
        }
    }
    .languages, .phones{
        color:white;
        float:right;
        margin-right: 25px;
        padding-right:10px;
        margin-top: 14px;
        position: relative;
        cursor:pointer;
    }
    .arrow{
        width: 6px;
        height: 4px;
        background: url(../img/arrowDown.png);
        position: absolute;
        right: 0px;
        top: 7px;
        transition:all 0.3s;
    }
    .active .arrow{
        transform: rotate(180deg);
    }
    
}
.b-btn, button{
    border:1px solid;
    color:@orange;
    outline:none;
    font-size:13px;
    display: inline-block;
    cursor: pointer;
    padding: 4px 19px;
    transition:all 0.3s;
    border-radius: 1px;
    text-align: center;
    min-width: 88px;
    text-decoration: none;
    &:hover, &.selected{
        background:@orange;
        color:white;
        border:1px solid @orange;
        text-decoration: none;
        background-position: 31px center;
        background-size:0px;
        &.selected{
            background: url(../img/check.png) 31px center no-repeat #f89125;
            background-size:9px 7px ;
        }
    }
    &.w100{
        width:100%;
        padding:4px 0;
        text-align: center;
    }
    &.fill-orange{
        background:@orange;
        color:white;
        border:1px solid @orange;
    }
    &.under-table{
        margin-bottom:30px;
    }
}
.white-block{
    width:100%;
    background:white;
    float: left;
    position: relative;
}
.languages{
    cursor:pointer;
    img{
        margin-right: 5px;
        margin-top: -3px;
    }
    .selected{
        
    }
    .flags{
        position: absolute;
        background: #fff;
        border: 1px solid transparent;
        color: #000;
        opacity:0;
        width: 55px;
        text-align: center;
        top: 23px;
        right: -8px;
        border-radius: 1px;
        overflow: hidden;
        height:0;
        padding:0 6px;
        z-index: 3;
        transition:all 0.3s;
        &:after{
            content: '';
            border-bottom: 5px solid white;
            border-right: 4px solid transparent;
            display: block;
            position: absolute;
            top: -5px;
            right: 6px;
            border-left: 4px solid transparent;
        }
    }
}
.languages.active, .phones.active{
    .flags, .otherHiddedContacts{
        height:auto;
        padding:6px;
        opacity:1; 
        border: 1px solid #E2E2E2;
        &.otherHiddedContacts{
            padding:12px;
        }
    } 
}
.otherHiddedContacts{
    position: absolute;
    background: #fff;
    border: 1px solid transparent;
    color: #000;
    opacity:0;
    width: 200px;
    text-align: center;
    top: 23px;
    right: -8px;
    border-radius: 1px;
    overflow: hidden;
    height:0;
    padding:0 12px;
    transition:all 0.3s;
    z-index: 3;
    .row{
        margin-bottom: 10px;
        text-align: left;
        &:last-child{
            margin-bottom:0;
        }
    }
}
.icons-c{
    margin-right:5px;
    float:left;
    i{
        width:21px;
        height:21px;
        float:left;
        margin-right:4px;
        background:url(../img/contactIcons.png);
        &.phone{background-position: 0px 0px;}
        &.whatsapp{background-position: -21px 0px;}
        &.viber{background-position: -42px 0px;}
        &.skype{background-position: -63px 0px;}
        &.email{background-position: -84px 0px;}
        &.location{background:url(../img/locationIcon.png);}
    }
}
.row{
    width:100%;
    clear: both;
    line-height: 23px;
}
.content{
    min-height:100%;
    &>.clear.border-2{
        margin-bottom:60px;
    }
    
}
.content-top{
    width:100%;
    height:151px;
    background:url(../img/contentBg.jpg) center;
    background-size:cover;
    border-bottom:1px solid #dddddd;
    overflow: hidden;
    font-family: @f1;
    font-weight: normal;
    position: relative;
    z-index: 2;
    &.not-breadcrumbs{
        height:auto;
        h1{
            padding-top:12px;
            padding-bottom: 41px;
        }
    }
    h1{
        color:white;
        width:100%;
        text-align: center;
        margin-top: 63px;
        font-size:25px;
        margin-bottom: 0px;
    }
    & + .inner{
        padding-top:36px;
    }
}
.index .content-top{
    height:900px;
    background:url(../img/indexBg.jpg) center;
    background-size:cover;
}
h2{
    font-size:25px;
}
h3{
    font-size:20px;
}
.spicial{
    a{
        display: block;
        width:100%/3;
        height:380px;
        background-size:cover !important;
        float:left;
        background-position: center !important;
        position: relative;
        font-family: @f1;
        box-shadow: -1px 0px 0px #DDDDDD inset;
        text-decoration: none !important;
        -webkit-filter:grayscale(50%);
        transition:all 0.3s;
        h3{
            padding:0 5%;
            color:white;
            text-decoration: none;
            font-size:34px; 
            border-bottom: 1px solid transparent;
            transition:all 0.4s;
        }
        .text-bl{
            padding:0 5%;
            color:white;
            font-size:13px;
            display: block;
        }
        .box-wrap{
            width:100%;
            position: absolute;
            bottom: 30px;
            transition:all 0.4s;
        }
        &:hover{
            -webkit-filter:grayscale(0%);
            .box-wrap{
                bottom:50px;
            }  
            h3{
                padding-bottom: 20px;
            }
        }
    }
    
}
.content{
    padding-bottom:80px;
    width:100%;
    float:left;
    background:#fbfbfb;
    .inner{
        h2{
            color:#242424;
            width:100%;
            float:left;
            margin-top:60px;
            margin-bottom:40px;
        }
    }
}
.breadcrumbs{
    text-align: center;
    font-size:13px;
    a,span{
        color:white;
    }
    a{
        text-decoration: underline;
        &:hover{text-decoration: none;}
    }
    &.in-content{
        text-align: left;
        margin-top: -30px;
        display: block;
        float: left;
        width: 100%;
        margin-bottom: 50px;
        *{
            color:#333;
        }
    }
}
.clear{
    width:100%;
    clear: both;
    float:left;
    &.border{
        border-top:1px solid #3a3a3a;
    }
    &.border-2{
        border-top:1px solid #dddddd;
    }
}
.overall-text{
    font-size:13px;
    color:#242424;
    font-family: @f1;
    width:100%;
    clear: both;
    float:left;
    margin-bottom:40px;
}
.col-2{
    &>div{
        width:47%;
        float:left;
        &:nth-child(2n+2){
            float:right;
        }
    }
}
.footer{
    background:#242424;
    width:100%;
    float:left;
    padding-top:16px;
    font-family: @f1;
    .logo{
        float: left;
        padding: 10px 5px;
        margin-right: 10px;
    }
    .copyright{
        float:left;
        color:#cecece;
        margin-top: 15px;
    }
    .manufacturer{
        float:right;
        color:#cecece;
        color:#cecece;
        margin-top: 15px;
        a{
          text-decoration: underline;
            color:#cecece;
            &:hover{
                text-decoration: none;
            }
        }
    }
    .footer-menu{
        list-style: none;
        margin:0;
        padding:0;
        width:300px;
        float:left;
        margin-top:27px;
        margin-bottom:20px;
        li{
            float:left;
            width:50%;
            margin-bottom:8px;
            a{
                color:#cecece;
                text-decoration: none;
                &:hover{
                    text-decoration: underline;
                }
            }
        }
    }
    .footer-blocks{
        &>div{
            float:left;
            width:100%/3;
            &:nth-child(2){
                text-align: center;
                .title{
                    margin-bottom:23px;
                }
                .wrapcontacts{
                    text-align: left;
                    display: inline-block;
                }
            }
            &:nth-child(3){
                text-align: right;
                .wrapSocial{
                    text-align: left;
                    display: inline-block;
                    margin-right:54px;
                }
            }
        }
        .title{
            font-size:16px;
            margin-top:7px;
            color:white;
            width:100%;
            float:left;
        }
        .row{
            color:#bdbdbd;
            margin:7px 0;
        }
    }
}
.social{
    padding-top: 15px;
    clear: both;
    a{
        background:url(../img/social.png) no-repeat;
        width:40px;
        height:40px;
        cursor:pointer;
        float:left;
        display:block;
        transition:all 0.3s;
        &:hover{
            transform: scale(0.85);
        }
        &.vk{background-position: 0px 0px;}
        &.fb{background-position: -36px 0;}
        &.tv{background-position: -73px 0px;}
        &.gp{background-position: -110px 0px;}
        &.inst{background-position: -147px 0;}
    }
}
.modal-wrap{
    display: none;
    .modal{
        width:355px;
        .padding-none__inner{
            padding:30px 35px;
            clear: both;
            .price{
                padding: 0px;
                span{
                    font-size:20px;
                    font-family: @f3; 
                }
            }
        }
    }
    .history-info{
        width:852px;
        
        .head-info {
            padding: 20px 0 15px 25px;
            border-left: 1px solid #ddd;
            margin-left: 200px;
            h3{
                font-family: @f7;
            }
            p{
                padding: 5px 0;
                margin: 0;
            }
        }
        .image{
            float:left;
            width:190px; 
            padding: 10px 0;
        }
    }
    .communication-ch-inp{
        margin-bottom:20px;
    }
}
.modal{
    background:white;
    padding:30px 35px;
    border-radius:2px;
    position: relative;
    transition:all 0.5s;
    &.padding-none{
        padding:0;
        
    }
    .b-btn{
        margin-top:15px;
        margin-bottom:20px;
    }
    h2{
        color:white;
        position: absolute;
        top:-60px;
        width:100%;
        text-align: center;  
        left:0;
        font-size:30px;
        font-family: @f5;
    }
    &>.input, &>.inputs{
        clear: both;
    }
}
.fancy-close{
    top: 6px; 
    right: 6px;
    cursor: pointer;
    height: 28px;
    width: 28px;
    transition: all 0.3s;
    position: absolute;
    transform: rotate(45deg);
    &:hover{
        &>div{
            background:@orange;
        }
    }
    &>div{
        width:28px;
        height:2px;
        background:#cecece;
        transition: all 0.3s;
        &:nth-child(1){
            margin-top:13px;
        }
        &:nth-child(2){
            transform: rotate(90deg);
            margin-top: -2px;
        }
    }
}
input{
    border: 1px solid #ddd;
    border-radius: 2px;
    outline: 0;
    font-family: @f1;
    padding: 7px 10px;
    transition:all 0.3s;
    background:white;
}
.input{
    margin-bottom:20px;
    position: relative;
    label{
        color:#333333;
        font-size:13px;
        font-family: @f5;
        display: block;
        width:100%;
        float:left;
    }
    textarea{
        height: 60px; 
    }
    input, textarea, select{
        border: 1px solid #ddd;
        border-radius: 2px;
        width: 90%;
        outline: 0;
        font-family: @f1;
        padding: 7px 5%;
        margin-top: 5px;
        transition:all 0.3s;
        background:white;
        &:focus{
            border: 1px solid @orange;
        }
        &.icon{
            padding-left:12%;
            width:83%;
            &.i-point{background: url(../img/input-icons/icon-1.png) 9px center no-repeat #fff; }
            &.i-date{background: url(../img/input-icons/icon-2.png) 9px center no-repeat #fff; }
            &.i-time{background: url(../img/input-icons/icon-3.png) 9px center no-repeat #fff; }
        }
    }
    select{width:100%;padding:6px 5%;   height: 32px; background:white;}
    &.floating-left{
        float:left;
        width:162px;
        margin-right:7px;
        input.icon{
            padding-left: 17%;
            width: 78%;
        }
    }
    &.required{
        label:after{
            content:'*';
            color:@orange;
        }
    }
    &.not-full{
        width:354px;
        &.float-left{
            margin-right:20px;
        }
        &.input-50{
            width:200px;
        }
    }
    &.input-type-2{
        width:31%;
        float:left;
        margin-right:2.3333%;
        margin-bottom:10px;
        input{
            width:10%;
            text-align: center;
            float:left;
            margin-right:4%;
            min-width:60px;
        }
        label{
            float: none;
            width: auto;
            margin-top: 11px;
            white-space: normal !important;
        }
        &:nth-child(3n+1){clear: both;}
    }
}
.form-footer{
     background: #fbfbfb;
     padding: 15px 30px;
    text-align: right;
    .b-btn{
        padding: 4px 50px;
        margin-top: 0;
        margin-bottom: 0;
    }
    h3{
        display: inline-block;
        margin-bottom:0;
        margin-right:35px;
        float:left;
    }
    span{
        line-height:10px;
    }
    .left{
        margin-top: 8px;
        float:left;
    }
}
#baby-chair{
    .modal{
        width:854px;
        padding:0;
        .chair-list{
            padding-top:20px;
            &>div{
                border-bottom:1px solid #ddd;
                padding:15px 30px;
                .chair-text{
                    padding-left: 123px;
                }
                .image{
                    float:left;
                    margin-right: 25px;
                    width:98px;
                }
                .input{
                    width:134px;
                    display: inline-block;
                }
            }
        }
    }
}
.black-overlay-search{
    width:508px;
    float:right;
    height: 855px;
    background:rgba(0,0,0,0.5);
    margin-top:45px;
    position: relative;
    &:after{
        content:'';
        display: block;
        position: absolute;
        background:rgba(0,0,0,0.5);
        width:99999px;
        height:100%;
        right:-99999px;
    }
    .search-inner{
        float:right;
        width:434px; 
        padding-top:120px;
        color:white;
        position: relative;
        z-index: 2;
        h3, label{
            color:white;
            font-family: @f5;
        }
        .clear.border{
            border-top:1px solid white;
            opacity:0.3;
        }
        .border{
            margin-bottom:40px;
        }
        .b-btn{
            margin-top:30px;
            margin-bottom:42px;
            font-family: @f1;
        }
        .row{
            margin:9px 0;
        }
    }
}
.communication-ch-inp{
    float: left;
    width:100%;
    input:checked + label + input[type="text"]{
        display:block;
    }
    input[type="text"]{
        margin-top:20px;
        float:left;
    }
}
.checkbox{
    input{display:none;}
    input:checked + label{
        &:before{
            content:'';
            background:@orange url(../img/check.png) center no-repeat;
            background-size:9px 7px;
            border:1px solid @orange;
             transition:background-size 0.3s;
        }
    }
    label:before{
        content:'';
        display: block;
        float:left;
        width:13px;
        cursor:pointer;
        height:13px;
        border:1px solid #dddddd;
        border-radius: 2px;
        margin-right:8px;
        transition:all 0.3s;
        margin-top: 3px;
        background:white url(../img/check.png) center no-repeat;
        background-size:0px 7px;
    }
    label{
        font-family: @f3;
    }
}
.input{
    .checkbox{
        float:left;
        margin-right:10px;
        margin-top: 10px;
    }
}
.radiobox{
    margin:5px 0;
    input{display:none;}  
    label:before{
        content:'';
        display: block;
        float:left;
        width:15px;
        cursor:pointer;
        height:15px;
        box-shadow: 0 0 0 1px #ddd inset;
        border-radius: 50%;
        margin-right:8px;
        transition:all 0.3s;
        margin-top: 3px;
    }
    input:checked + label{
        &:before{

            box-shadow: 0 0 0 5px @orange inset;
        }
    }
}
.checkboxes{
    padding:26px 0;
    clear: both; 
    &.center{
        text-align: center;
        .checkbox{
            display: inline-block;
            margin:0 9px;
        }
    }
}
.car-list{
    width:100%;
    clear: both;
    &>div{
        background:white;
        width:100%;
        box-shadow:0px 0px 0px 1px @borderColor;
        border-radius:2px;
        float: left;
        margin-bottom:30px;
        .images{
            min-height: 306px;
            width: 370px;
            height: 100%;
            box-shadow: 1px 0px 0px #ddd;
            float: left;
            .big{
                width: 98%;
                height: 230px;
                box-shadow: 0 -1px 0 #ddd inset;
                background-size: cover!important;
                background-position: center!important;
                padding: 1%;
            }
            .caruseul{
                position: relative;
                width:100%;
                overflow: hidden;
                .wrap-images{
                     width:300px;
                     margin:0 auto;
                    .long-line{
                        width:9999px;
                    }
                }
                
            }
        }
        
    }
    .owl-item>div{
        width:100px;
        height:74px;
        background-size: cover!important;
        background-position: center!important;
        float:left;
        cursor:pointer;
    }
    
}
.nav{
    &>div{
        width: 4px;
        height: 15px;
        overflow: hidden;
        cursor: pointer;
        padding: 3px 3px;
        position: absolute;
        top:50%;
        margin-top:-8px;
        &.left{
            left:-22px;
            display: none;
        }
        &.right{
            right:-22px;
            transform:rotate(180deg);
            display: none;
        }
        &>div{
            width: 12px;
            height: 12px;
            border: 2px solid #d1d1d1;
            float: left;
            transform: rotate(45deg);
            transition:all 0.3s;
            
        }
        &:hover>div{
            border: 2px solid @orange;
        }
    }
}
.arrow-accordeon{ 
    width: 4px;
    height: 15px;
    overflow: hidden;
    cursor: pointer;
    padding: 3px 3px;
    position: relative;
    float:right;
    transition:all 0.5s;
    transform: rotate(-90deg);
    &>div{
        width: 12px;
        height: 12px;
        border: 2px solid #d1d1d1;
        float: left;
        transform: rotate(45deg);
        transition:all 0.3s;
        
    }
    &:hover>div{
        border: 2px solid @orange;
    }
    &.arrow-top{
        transform: rotate(90deg);
        &>div{
            border: 2px solid @orange;
        }
    }
}
.content-list-car{
    float: left;
    padding: 20px 30px;
    box-shadow: 1px 0px 0px #ddd inset;
    width: 880px;
    h3{
        font-family:@f7;
        border-bottom:2px solid @orange;
        padding-bottom: 8px;
    }
    .left{
        width:570px;
        float:left;
    }
    p{
        font-size: 13px;
        font-family: @f5;
        margin-top: 7px;
        margin-bottom: 0;
        span{
            font-size:15px;
            font-family: @f3;
        }
    }
    .right{
        width:204px;
        float:right;
        padding: 20px 30px;
        padding-top:0;
        position: relative;
        .b-btn{
            margin-top:25px;
        }
        p{
            margin-top: 15px;
            font-family: @f3;
            &:nth-child(1){margin:0;}
            b{
               font-family: @f7; 
            }
        }
    }
    .picking{
        margin-top:15px;
        width:100%;
        overflow: hidden;
        p{
            font-family: @f3;
            &.title{
                font-family: @f5;
            }
        }
    }
}
.price{
    font-size:30px;
    font-family: @f7;
    padding: 12px 0 10px 0;
}
.car-icons{
    margin-bottom:12px;
    span{
        margin-right:7px;
        font-size:11px;
        img{
            margin-right:3px;
            margin-top: -3px;
            height:auto !important;
        }
    }
}
.filter-inputs{
    padding:10px 0 20px 0;
    float:left;
    width:100%;
    .input{
        margin-top: 10px;
        margin-bottom:0;
    }
}
.tooltip{
    span{
        color:@orange;
        font-family: @f7 !important; 
        cursor:pointer;  
    }
    .triangle-tooltip{
        width: 7px;
        height: 7px;
        border-top: 1px solid @orange;
        border-right: 1px solid @orange;
        background: #fff;
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
        position: absolute;
        z-index: 2;
        opacity: 1;
        display: none;
        margin: 23px 0px 0px -8px;
    }
    &>div{
        position: absolute;
        background: #fff;
        border: 1px solid #f89125;
        border-radius: 2px;
        padding: 8px;
        margin-top: 6px;
        left: 25px;
        right: 23px;
        font-size: 11px;
        display:none;
        line-height: 13px; 
    }
    &:hover{
        &>div{
           display: block;
        }
        .triangle-tooltip{
            display:inline-block;
        }
    }
}
.benefit-banner{
    float:left;
    width:100%;
    position: relative;
    margin-bottom: 30px;
    img{
        width:100%;
        float:left;
    }
    & + .checkboxes{
        padding-bottom: 50px;
    }
    .text-1, .text-2, .contacts{
        color:white;
        font-family: @f3;
        font-size:19px;
        position: absolute;
        opacity:0;
        width: 27%;
        &.text-1{
            top:31px;
            left:52px;
            transition:opacity 1s;
        }
        &.text-2{
            right:52px;
            bottom:46px;
            transition:opacity 1s 0.5s;
        }
        &.contacts{
            left:38%;
            top:58px;
            width: 30%;
            transition:opacity 1s 1s;
            .title{ 
                margin-bottom:25px;
            }
            .contactIc{
                font-size:15px;
                .row{
                    margin: 8px 0;
                }
            }
        }
    }
    &.animated{
        .text-1, .text-2, .contacts{
            opacity:1;
        }
    }
}
.orange{
    color:@orange;
    font-family: @f5;
}
.wrap-element{
    margin-bottom: 40px;
    float:left;
    width:100%;
    h3{
        font-family: @f7;
        padding-bottom: 8px;
    }
    .title{
        font-family: @f3;
        font-size:15px;
        margin-bottom:20px;
    }
    &.total{
        .overlay-content>div>span{
            display: inline-block;
        }
    }
    .overlay-content{
        background: #fff;
        width: 100%;
        box-shadow: 0 0 0 1px #ddd inset;
        border-radius: 2px;
        float: left;
        border-top:2px solid @orange;
        &>div{
            padding:20px 25px;
        }
        a{
            color:#333333;
            text-decoration: underline;
            transition:all 0.3s;
            &:hover{
                color:@orange;
            }
        }
        .dop{
            width:100%;
            td{
                padding:0;
                width:50%;
                vertical-align: top;
                &:nth-child(1){
                    &>div{
                       border-right:1px solid #ddd; 
                    }
                }
                &>div{
                    padding:20px;
                    position: relative;
                    float:left;
                    border-bottom:1px solid #ddd; 
                    .icon{
                        float:left; 
                        position: absolute;
                        left:20px;
                        top:20px;
                        
                    }
                    .content-dop{
                        float:left;
                        padding-left: 55px;
                        .title{
                            margin-top: 5px;
                        }
                        .b-btn{
                            display: table;
                            margin: 20px 0px 35px 0;
                            padding: 4px 0px;
                            width:164px;
                        }
                    }
                }
            }
        }
    }
    &.information-about-car{
        .image{
            width: 230px;
            margin-top: -19px;
            margin-left: -24px;
            margin-bottom: -19px;
        }
        td{
           &>span{
                margin-right: 48px;
                float:left;
            } 
        }
    }
    &.total{
        .overlay-content{
            span{
                margin-right: 29px;
            }
        }
    }
}
.payment p{
    color: #6e6e6e;
    font-style: italic;
    margin-top: -30px;
    float: left;
    width: 100%;
}
.client-data{
    .overlay-content{
        &>div{
           padding-bottom:70px; 
        }
    }
    .input{
        width: 346px;
        float:left;
        margin-right:22px;
        &:nth-child(2n+1){
            clear: both;
        }
        &:nth-child(2n+2){
            margin-right:0;
        }
        label{
            font-family: @f3;
        }
    }
    p{
        margin-top: 18px;
        float: left;
        width: 100%;
    }
    .checkboxes{
        float:left;
        padding-top: 30px;
        padding-left:30px;
        clear: none;
        .checkbox{
            float:left;
            margin-right:20px;
        }
    }
    .b-btn{
        clear: both;
        display: block;
        float: left;
    }
}
.upload_file{
    padding-top:20px;
    clear: both;
    margin-bottom:30px;
    input[type="file"]{
        display: none;
    }
    label{
        cursor:pointer;
        &:before{
            content:'';
            background:url(../img/link.png);
            display: block;
            width:15px;
            height:15px;
            float:left;
            margin-right:11px;
            margin-top: 2px;
        }
    }
}
.btns{
    clear: both;
    width:100%;
    float:left;
    margin-top:50px;
    margin-bottom:60px;
    .b-btn{
        width:202px;
        padding:4px 0;
        margin-right:15px;
        float:left;
        margin-bottom:10px;
       
    }
    span{
        float:left;
        margin-top:4px;
    }
    a{
        color: #333;
        transition:all 0.3s;
        text-decoration: underline;
        &:hover{
            text-decoration: none;
            color:@orange;
        }
    }
}
#shipping-auto{
    .modal{
        width:800px;
        input, textarea{
            padding:7px 1%;
            width:98%; 
        }
    }
    .shipping-map{
        margin-top:2px;
    }
}
.spicial-other-pages{
    margin-top: 25px;
    margin-bottom:60px;
    width:100%;
    float:left;
    &>a{
        width:32%;
        margin-right:2%;
        height:240px;
        &:last-child{
            margin:0;
        }
    }
}
.color{
    width:17px;
    height:17px;
    border-radius: 2px;
    display: inline-block;
    margin-top: 5px;
}
table.type-1{
    background:white;
    width:100%;
    float:left;
    box-shadow:0px 0px 0px 1px #ddd;
    border-radius: 2px;
    margin-bottom: 50px;
    .head{
        td{
            border:0;
            background:white !important;
            border-bottom:3px solid @orange;  
        }
    }
    .car-icons{
        margin-bottom: 0;
        span{
            font-size:13px;
            img{
                margin-top: -12px;
            }
            
        }
    }
     td{
        text-align: left;
        border-right: 1px solid #ddd;
        border-bottom: 1px solid #ddd;
        padding:8px 15px; 
        font-family: @f3;
        transition:all 0.3s;
        &:last-child{
            border-right:0;
        }
    }
    tr{
        &:hover{
            td{
                background:#fffaf4;
            }
        }
        &:last-child{
            td{
                border-bottom:0;
            }
        }
    }
    &.prices{
        td{
            &:nth-child(2){
                width:100px;
            }
        }

    }
}
.price-content{
    clear: both;
    float:left;
    width:100%;
    h3{
        margin-bottom: 25px;
    }
    .left-col{
        float:left;
        width:850px;
    }
    .right-col{
        float:right;
        width:408px;
    }
}
.car-prices{
    background:white;
    width:100%;
    float:left;
    box-shadow:0px 0px 0px 1px #ddd;
    border-radius: 2px;
    margin-bottom: 50px;
    .head{
        td{
            border:0;
            border-bottom:3px solid @orange;  
        }
    }
    .no-bg{
        color:#444 !important;
        border-left:1px solid #ddd;
        border-bottom:1px solid #ddd;
    }
    td{
        text-align: center;
        padding:10px 0;
        border-right:1px solid white;
        border-bottom:1px solid white;
        width: 76px;
        font-family: @f3;
        &:last-child{
            border-right:0;
        }
        &.rowspan{
            width:380px; 
            background:white !important;
            text-align: left;
            h4{
                margin-bottom:0;
            }
        }
        .image{
            width:200px;
            float:left;
        }
        .car-icons{
            margin-top:10px;
        }
    }
    tr{
        &.color-1{
            td{
                background:#c8cbca;
                border-top:0;
            }
        }
        &.color-2{
            td{
                background:#242424;
                color:white;
            }
        }
        &.color-3{
            td{
                background:@orange;
                color:white;
            }
        }
        &.color-4{
            td{
                background:#f4c3a0 url(../img/strih.png);
                border-bottom:0;
            }
        }
        &.clear{
            float:none;
            td{
                height:1px;
                background:#ddd;
                padding: 0;
            }
        }
    }
    &.lc-prices{
        td.rowspan{
            width:330px;
        }
    }
}
.calendar{
    width:100%;
    float:left;
    clear: both;
    .month{
        width:48%;
        background:white;
        border-radius: 2px;
        box-shadow:0 0 0 1px #ddd;
        margin-bottom:19px;
        float:left;
        &:nth-child(2n+2){
            float:right
        }
        .head{
            td{
                border-bottom:3px solid @orange;
            }
        }
    }
    td{
        font-size:11px;
        text-align: center;
        font-family: @f3;
    }
    tr.week{
        td{
            font-size:9px;
        }
    }
    tr.days{
        td{
            border-top:1px solid #ddd;
            border-right:1px solid #ddd;
            &:last-child{
                border-right:0;
            }
        }
    }
    tr{
        td.color-1 {background:#c8cbca;}
        td.color-2 {background:#242424;color:white;}
        td.color-3 {background:@orange;color:white;}
        td.color-4 {background:#f4c3a0 url(../img/strih.png);}
    }
}
.contacts-line{
    float:left;
    width:40%;
    .row{
        clear: both;
        margin:20px 0;
    }
}
.contacts-map{
    margin-top: 40px;
    clear: both;
    float: left;
    position: absolute;
    bottom: -80px;
    top: 0px;
    left:50%;
    right:0; 
}
.partners-list{
    width:100%;
    clear: both;
    text-align: center;
    margin-top: 20px;
    &>div{
        width:24%;
        display: block;
        float:left;
        margin:5px 0.5%;
        height:150px;
        overflow: hidden;
        position: relative;
        background:white;
        border-radius: 2px;
        box-shadow:0px 0px 0px 1px #ddd;
        .image{
            width: 100%;
            vertical-align: middle;
            height: 100%;
            transition: all 0.5s;
            position: relative;
            img{
                max-width:70%;
                max-height:100%;
                position: absolute;
                left:50%;
                top:50%;
                transform: translate(-50%, -50%);
            }
        }
        .title{
            font-family: @f3;
            font-size:16px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
        .sitename{
            color:#007cb6;
            text-decoration: underline;
        }
        &:hover{
            .image{
                height:60%;
            }
        }
    }
}
.margin-0{
    margin:0 !important;
}
.doc{
    margin: 10px 0;
    display:inline-block;
    margin-right: 30px;
    &:before{
        content:'';
        background:url(../img/pdf.png);
        width:18px;
        height:19px;
        float:left;
        display: block;
        margin-right:5px;
    }
}
.nav-tabs{
    display: table;
    width:100%;
    &>div{
        display: table-row;
        &>div{
            display: table-cell;
            text-align: center;
            &:first-child{
                text-align: left;
            }
            a{
                color: #333;
                padding: 13px 7px;
                text-decoration: none;
                display: inline-block;
                transition:all 0.3s;
                &.active{
                    box-shadow: 0px -3px 0px @orange inset;
                }
                &:hover{
                    color:@orange;
                }
            }
            &.back-link{ 
                text-align: right;
                a{
                     text-decoration: underline;
                     color:#4291c1 !important;
                     &:hover{
                         text-decoration: none;
                     }   
                }
            }
        }
    }
    &.col-3-nav{
        &>div>div{
            width:33%;
            &:last-child{
                text-align: right;
            }
        }
    }
}
.acordeon-wrap-element{
    width:100%;
    border-radius: 2px;
    float:left;
    background:white;
    box-shadow:0px 0px 0px 1px #ddd;
    margin-bottom:12px;
    .button-accordeon{
        padding: 15px 20px;
        transition:all 0.3s;
        cursor: pointer;
        &:hover, &.active{
            box-shadow: 0px 2px 0 @orange inset;
            .arrow-accordeon>div{
                border: 2px solid @orange;
            }
        }
    }
    .content-acordeon{
        padding: 20px;
        border-top: 1px solid #ddd;
        display:none;
    }
}
.status-bar{
    clear: both;
    width:100%;
    float:left;
    margin-bottom: 15px;
    position: relative;
    border-radius: 2px;
    overflow: hidden;
    .line-bar{
        height:10px;
        width:100%;
        background: rgb(248,145,37); /* Old browsers */
        background: -moz-linear-gradient(left,  rgba(248,145,37,1) 0%, rgba(138,232,4,1) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(left,  rgba(248,145,37,1) 0%,rgba(138,232,4,1) 100%); /* Chrome10-25,Safari5.1-6 */ 
        background: linear-gradient(to right,  rgba(248,145,37,1) 0%,rgba(138,232,4,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */        
    }
    .white-bar{
        position: absolute;
        height: 8px;
        right: 0;
        background: #FBFBFB;
        top: 0;
        border: 1px solid #ddd;
        border-left: 0;
    }
    &+p{
        margin-bottom:50px;
    }
}
table.type-1.center-td{
    td{
        text-align: center;
    }
}
.person-discounts{
    width:100%;
    float:left;
    clear: both;
    display: table;
    &>div{
        margin-bottom: 22px;
        background: #fff;
        margin-right: 22px;
        position: relative;
        border-radius: 2px;
        box-shadow: 0 0 0 1px #ddd;
        display: block;
        width: 23.5%;
        float:left;
        margin-right:2%;
        &:nth-child(4n+4){
            margin-right:0;
        }
        &.done{
            box-shadow: 0 0 0 1px #ddd, 0px 4px 0 @orange inset;
        }
        &>div{
           padding: 15px 25px;  
            overflow: auto;
            height: 140px;
        }
    }
    .discount-count{
        font-family: @f7;
        font-size:39px;
        color:@orange;
        float:left;
        position: absolute;
        left:15px;
        top:25px;
    }
    .text{
        padding-left: 75px;
    }
}
.center{
    text-align: center !important;
}
.history-table{
    min-width:900px;
    .head td{
        cursor:pointer;
    }
    td{
        text-align: center !important;
    }
    img{
        height: 60px;
        margin: -8px 0;
    }
    .sort{
        background: url(../img/sort-arrows.png);
        display: inline-block;
        width: 6px;
        height: 13px;
        margin-left: 6px;
        position: relative;
        top: 2px;
        background-position: -12px 0;
        &.top{
            background-position: -6px 0;
        }
        &.bottom{
            background-position: 0px 0;
        }
    }
}
.wrap-table{
    width:100%;
    overflow: auto;
    padding:1px;
    margin-bottom:50px;
    table{
        margin-bottom:0;
        min-width:600px;
    }
}
.orange-line{
    td{
        padding: 0 !important;
        border-top: 3px solid @orange;
    }
}
.end-table{
    td{
        border-top:3px solid @orange;
        padding:6px 0;   
        padding: 20px 0 !important;
        background:white !important;
    }
} 
.search{
    position: relative;
    float:left;
    width:100%;
    margin-bottom: 20px;
    input[type="text"]{
        border: 1px solid #ddd;
        border-radius: 2px;
        outline: 0;
        font-family: museo1;
        margin-top: 5px;
        padding: 7px 0;
        transition: all .3s;
        display: block;
        background: #fff;
        float: none;
        width: 100%;
        text-indent: 40px;
        margin:0;
    }
    input[type="submit"]{
        position: absolute;
        background: url(../img/search.png) center no-repeat;
        width: 42px;
        height: 30px;
        top: 1px;
        left: 0px;
        border: none;
        outline: none;
        cursor: pointer;
        font-size: 0;
    }
}
.edit{
    display: inline-block;
    background:url(../img/edit.png);
    width:18px;
    height:18px;
    cursor:pointer;
    &:hover{
        background-position: 0px -18px;
    }
}
.calendar-slider{
    width:605px;
    float:left;
    margin-right:35px;
    .month{
        width:100%;
        box-shadow: 0 0 0 1px #ddd inset;
    }
    &>.month{
        display:none;
    }
}
.calendar-and-colors{
    margin-bottom:60px;
    width: 100%;
    clear: both;
    float: left;
    .calendar tr td{
       &.color-1{background:@orange; color:white;}
       &.color-2{background:#4da719; color:white;}
       &.color-3{background:#7b519d; color:white;}
       &.color-4{background:#6996c7; color:white;} 
    }
    .b-btn{clear: both;display: block; float:left; margin-top:20px;}
    .owl-controls{
        .nav{
            &>div{
                top: 4px;
                width: 2px;
                height: 9px;
                margin-top:0;
                &.right{
                    right: 6px;
                }
                &.left{
                    left: 6px;
                }
                &>div{
                    width: 5px;
                    height: 5px;
                }
            }
        }
    }
}
.colors-calendar{
    float:left;
    &>div{
        &>div{
            width: 20px;
            height: 20px;
            border-radius: 2px;
            display: inline-block;
            margin-top: 5px;
            margin-right: 5px;
            position: relative;
            top: 5px;
            &.color-1{background:@orange;}
            &.color-2{background:#4da719;}
            &.color-3{background:#7b519d;}
            &.color-4{background:#6996c7;}
        }
    }
}
.gray-link{
    color:#444 !important;
}
.white-overlay{
    background:white;
    width:100%;
    float:left;
    border-radius: 2px;
    box-shadow:0px 0px 0px 1px #ddd inset;
    &>div{
        padding:32px;
    }
}
.add-order-overlay{
    .input{
        width:354px;
        clear: both;
        input.icon{
            padding-left: 8%;
            width: 87%;
        }
        table{
            float: none;
        }
        &.table-input{
            width:650px;
            margin-bottom:-15px;
            label{
                margin-bottom:7px;
            }
        }
        &.textarea{
            width:650px;
            textarea{
                padding: 7px 2%;
                width: 96%;
            }
        }
    }
    .checkbox{
        i{
            margin-top: 2px;
        }
    }
    .add-input{
        position: absolute;
        right: -90px;
        bottom: 8px;
    }
    .inputs{
        width:354px;
        .input{
            width:48%;
            clear: none;
            input.icon{
                padding-left: 16%;
                width: 79%;
            }
        }
    }
    .dop-info{
        clear: both;
        width:100%;
        float:left;
        margin-top:40px;
        margin-bottom:20px;
    }
}
.add-button{
    margin-top:30px;
    margin-right:10px;
}
.shares-table{
    td:nth-child(5){
        max-width:430px;
        min-width:200px;
    }
}
.navigation-tabs{
    box-shadow:0px 0px 0px 1px @orange inset;
    color:@orange;
    float:left;
    border-radius: 2px;
    margin-bottom:40px;
    &>div{
        float:left;
        width:160px;
        text-align: center;
        padding:5px 0;
        cursor:pointer;
        box-shadow:-1px 0px 0px @orange inset;
        &:last-child{
            box-shadow:0px 0px 0px @orange inset;
        }
        &.active{
            background:@orange;
            color:white;
        }
    }
}
.tab-content{
    width:100%;
    clear: both;
    float:left;
    &>div{
        display:none;
        .overlay-content{
            &>div{
                float: left;
                width: 96%;
                padding: 20px 2%;
                .input-50{
                    margin-bottom:0;
                }
            }
            label{
                white-space: nowrap;
            }
            .dop-input{
                margin-top: 4px;
                float:left;
            }
        }
    }
    .car-prices{
        min-width:600px;
    }
}
.insurance-tab{
    .white-overlay{
        margin-bottom:40px;
        &>div{
            padding: 10px 25px;
        }
    }
    table.type-1{
        width:412px;
        margin-right:59px;
        &:last-child{
            margin-right:0;
        }
    }
}
.dop-input{
    input{
        margin:0 0 0 6px;
    }
    .b-btn{
        margin-left:20px;
        position: relative;
        top:-1px;
    }
}
.login-and-register{
    position: absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    height:auto;
    background:url(../img/loginBg.jpg);
    background-size:cover;
}
.login-modal{
    position: absolute;
    top:50%;
    left:50%;
    transform:translate(-50%, -50%);
    background:white;
    border-radius: 2px;
    display: none;
}
.modal-inner{
    padding:30px 38px;
    display: none;
    &#login, &#remake-password{
        width:350px;
        &#login{
            display:block;
        }
    }
    &#register{
        width:730px;
    }
    .b-btn{
        margin-top:15px;
        margin-bottom:10px;
    }
    h2{
        color:white;
        position: absolute;
        top:-60px;
        width:100%;
        text-align: center;  
        left:0;
        font-size:30px;
        font-family: @f5;
    }
}
.footer-modal{
    padding:10px 38px;
    border-top:1px solid #ddd;
    text-align: center;
    clear: both;
    span{
        color:#7d7d7d;
        text-decoration: none;
    }
    a{
        color:#333333;
        transition:all 0.3s;
        &:hover{
            color:@orange;
        }
    }
}
.popup-link{
    cursor: pointer;
}
.modal-wrap{
    .col-2-inputs{
        width:730px;
        .input{
            &:nth-child(2n+1){
                clear: both;
            }
        }
        .textarea{clear: both;}
    }
    .text-modal{
        width:500px;
    }
}
.border-box{
    border-bottom:1px solid #ddd;
    padding:12px 0;
    p{
        margin:0;
    }
    &:last-child{
        border-bottom:0;
    }
}
.two-btn{
    width: 100%;
    height: 40px;
    .b-btn{
        width: 50%;
        padding: 4px 0;
        display: block;
        float: left; 
        border: 0;
        box-shadow: 0px 0px 0px 1px #F89125 inset; 
    }
}
.autocomplete-suggestions{
    background: white;
    border-radius:2px;
    border:1px solid #ddd;
    padding:5px 0;
    margin-top: 3px;
    .autocomplete-suggestion{
        padding: 6px 14px;
        cursor:pointer;
        color:#494949;
        &:hover{
            background:@orange;
            color:white;
        }
    }
}
.drop-down{
    background: white;
    position: absolute;
    width:100%;
    z-index: 5;
    left:0;
    border-bottom:1px solid #ddd;
    padding:20px 0;
    display:none;
    top: 78px;
    .inner{
        width:640px;
        text-align: left;
        margin:0 auto;
        &>div{
            clear: both;
            float:left;
            margin-bottom:10px
        }
    }
    .left-col{
        float:left;
        width:100px;
        position: relative;
        padding-right:10px;
        .little{
            font-size:11px;
        }
        img{
            position: absolute;
            left: -17px;
            top: 3px;
        }
    }
    .right-col{
        float:left;
        width:530px;
        &>div{
            border: 1px solid #ddd;
            color: #494949;
            padding: 1px 11px;
            cursor: pointer;
            transition: all .3s;
            float: left;
            border-radius: 2px;
            margin-right: 5px;
            font-size:11px;
            margin-bottom: 7px;
            &:hover{
               border:1px solid @orange;
               color:@orange; 
            }
        }
    }
}
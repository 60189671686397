@media screen and(max-width:1370px){
    .inner{
        width:92%;
        margin:0 4%;
    }
    .right-header{
        clear: both;
        float:right;
        padding-bottom:7px;
        .feedback{
            margin-right:0;
        }
    }
    .black-overlay-search{
        height: 814px;
        margin-top: 86px;
    }
    .content-top{
        height:190px;
        h1{
            margin-top: 95px;
        }
    }
    .car-list{
        .content-list-car{
            padding:20px 0% 20px 3%;
            width: 54%;
            .left{
                width:100%;
            }
            .right{
                float:left;
                padding: 10px 0px;
                width:100%;
                .price{
                    margin-top:7px;
                }
            }
        }
    }
    .filter-inputs{
        text-align: center;
        .floating-left{
            display:inline-block;
            float:none;
        }
    }
    .client-data .checkboxes{
        padding-top:0;
        padding-left:0;
        clear: both;
    }
    .spicial-other-pages{
        &>a{
            transition:all 0s;
            filter:grayscale(0);
            h3{
                font-size: 20px;
            }
        }
    }
    .price-content{
        .left-col{
            width: 66%;
        }
        .right-col{
            width: 32%;
        }
    }
    .person-discounts{
        &>div{
            width: 32%;
            &:nth-child(4n+4){margin-right:2%;}
            &:nth-child(3n+3){margin-right:0;}
        }
    }
    .car-prices td.rowspan{
        text-align: center;
        .image{
            width:100%;
        }
    }
}

@media screen and(max-width:1200px){
    .benefit-banner{
        height:300px;
        overflow: hidden;
        img{
            position: absolute;
            top:0;
            left:0;
            height:100%;
            width:1360px;
            max-width: none;
        }
        .text-1, .text-2, .contacts{
            width:auto !important;
            left: 52px !important;
            top: 31px !important;
            margin-bottom:18px;
            border-bottom: 1px solid rgba(221, 221, 221, 0.34);
            padding-bottom: 4px;
            max-width:85%;
            &.contacts{
                border-bottom:none;
            }
        }
        &.animated>.text-1, &.animated>.text-2, &.animated>.contacts{
            position: relative;
            right:0;
            bottom:0;
            clear: both;
            float:left;
        }
    }
    .nav-tabs{
        &>div{
            text-align: center;
            &>div{
                display: inline-block;
            }
        } 
    }
    .spicial{ 
        a h3{
            font-size: 28px;
        }
        &.spicial-other-pages{
            a h3{
                font-size: 20px;
            }
        }
    }
}
@media screen and(max-width:1121px){
    .drop-down{
        top: 125px;
    }
}
@media screen and(max-width:1000px){
    .header{
        .b-btn{
            clear: both;
        }
        .right-header{
            clear: none;
        }
        .feedback{
            margin-right: 25px;
        }
    }
    .car-list{
        .content-list-car{
            width:45%;
        }
    }
    .price-content{
        .left-col{
            width: 55%;
        }
        .right-col{
            width: 40%;
        }
    }
    .person-discounts{
        &>div{
            width: 49%;
            &>div{
                height:200px;
            }
            &:nth-child(4n+4){margin-right:2%;}
            &:nth-child(3n+3){margin-right:2%;}
            &:nth-child(2n+2){margin-right:0;}
        }
        .discount-count{
            width: 100%;
            position: static;
            text-align: center;
            margin: 10px 0px 15px 0px;
        }
        .text{
            padding-left:0;
            text-align: center;
        }
    }
    .car-prices td.rowspan{
        text-align: center;
        .image{
            width:100%;
        }
    }
    .add-order-overlay{
        .add-input{
            position: static;
        }
        .table-input.input{
            .add-input{
                margin-top:-40px;
                margin-bottom:30px;
                float:right;
            }
        }
        .input{
            .add-input{
                margin-top:5px;
                float:right;
            }
        }
    }
    .insurance-tab{
        table.type-1{
            width:48%;
            margin-right:0 !important;
            &:nth-child(2n+2){
                float:right;
            }
        }
    }
    .input.input-type-2{
        width:48%;
        margin-right:0;
        &:nth-child(2n+2){float:right;}
        &:nth-child(2n+1){clear:both;}
        &:nth-child(3n+1){clear: none;}
    }
    .dop-wrap{
        .dop-input{
            clear: both;
            span:nth-child(1){
                display: block;
                margin-bottom:5px;
            }
            input{
                margin:0;
                margin-bottom:10px;
            }
        }
        .input-type-2{
            float:left !important;
            margin-top:10px;
        }
        .clear{
            border-top:1px solid #ddd;
            margin-top:15px;
        }
    }
    .type-1{
        .col-2{
            &>div{
                width:100%;
            }
        }
    }
    .modal-wrap .history-info{
        width:auto;
    }
    #shipping-auto .modal{
        width: 80%;
        padding: 30px 10%;
    }
    #baby-chair .modal{
        width:100%;
    }
}
@media screen and(max-width:850px){
    .header{
        position: fixed;
        .menu{
            display:none;
        }
        .right-header{
            display: none;
        }
        .b-btn{
            clear: none;
            float:left;
            margin-left:25px;
            margin-top: 15px;
            margin-bottom: 15px;
        }
        .logo{
            margin-top: 15px;
            
        }
        .menu-nav{
            position: absolute;
            right:0;
            top:0;
            height: 30px;
            width: 50px;
            padding-top: 15px;
            padding-bottom: 15px;
            cursor: pointer;
            padding-right: 4.2%;
            padding-left: 5.2%;
            background: #2B2B2B;
            &>div{
                background:white;
                height:2px;
                width:100%;
                transition:all 0.3s;
                &:nth-child(2){
                    margin:12px 0;
                }
            }
        }
    }
    .black-overlay-search{
        height: auto;
        margin-top: 0;
        width:100%;
        padding-bottom:50px;
        &:before {
            content: '';
            display: block;
            position: absolute;
            background: rgba(0,0,0,.5);
            width: 99999px;
            height: 100%;
            left: -99999px;
        }
        .search-inner{
            width:100%;
        }
    }
    .index .content-top{
        height: auto;
    }
    .body-showmenu{
        overflow: hidden;
        .show-mobile{
            bottom:0;
            top:0;
            left:0;
            right:0;
            overflow: auto;
        }
        .menu-nav{
            &>div{
                &:nth-child(2){opacity:0; margin:0; height:0;}
                &:nth-child(1), &:nth-child(3){position: relative;top:50%;}
                &:nth-child(1){transform:rotate(45deg);}
                &:nth-child(3){transform:rotate(-45deg); margin-top: -2px;}
            }
        }
        .header{
            background: rgb(37, 37, 37);
           .logo, .b-btn{
                display: none;
            }
            .menu{
                display: block;
                padding-top: 70px;
                padding-bottom: 20px;
                float: left;
                &:before{
                    content: 'Меню';
                    color: #FFFFFF;
                    right: 0;
                    display: block;
                    padding: 20px 0;
                    background: #424242;
                    position: absolute;
                    left: 0;
                    padding-left: 5%;
                    top:0;
                    font-size:18px;
                }
                li{
                    width: 100%;
                    margin-left: 0;
                    border-bottom: 1px solid rgb(25, 25, 25);
                    border-top: 1px solid rgb(64, 64, 64);
                    &:first-child{border-top:0;}
                    &:last-child{border-bottom:0;}
                    a{
                      font-size:20px;  
                    }
                }
            } 
        }
        .right-header{
            display: block;
            width:100%;
            .languages{
                width:100%;
                padding:0;
                margin:0;
                margin-top:20px;
                .arrow{
                    display: none;
                }
                .flags{
                    width: 60%;
                    position: static;
                    padding: 10px 0;
                    border: 0;
                    margin: 14px auto 0 auto;
                    &>div{
                        font-size:20px;
                        margin: 15px 0;
                    }
                    &:after{
                        display:none;
                    }
                }
                .flag-block{
                    color: #fff;
                    text-align: center;
                    padding: 12px 0 5px 0;
                    font-size: 30px;
                }
            }
            .feedback{
                display:none;
            }
            .phones{
                width:100%;
                padding:0;
                margin:0;
                float:left;
                .mainPhone{
                    display:none;
                }
                .arrow{
                    display: none;
                }
                .otherHiddedContacts{
                    position: static;
                    display: block;
                    padding: 12px 5%;
                    height: auto;
                    opacity: 1;
                    float: left;
                    width: 90%;
                    background: #4A4A4A;
                    .row{
                        font-size:20px;
                        color:white;
                    }
                }
            }
        }
    }
    .spicial{
        a{
          width:50%;
            border-bottom:1px solid #ddd;
            &:nth-child(3){
                width:100%;
            }
            h3{
                font-size:16px;
            }  
        }
    }
    .footer-blocks{
        &>div{
            width: 100% !important;
            clear: both;
            .footer-menu{
                width:100%;
                li{
                    width:100%/3;
                }
            }
            &:nth-child(2),&:nth-child(3){
                float:left;
                width:50% !important;
                clear: none;
                margin-top:40px;
                margin-bottom:40px;
            }
        }
    }
    .car-list{
        &>div{
            .images{
                width:100%;
                .big{
                    background-size:350px !important;
                    background-repeat: no-repeat !important;
                }
            }
        }
        .content-list-car{
            width: 92%;
            padding: 20px 4%;
            box-shadow: 0 0 0;
            .right{
                text-align: center;
                float:none;
                width:100%;
                clear: both;
            }
        }
    }
    .checkboxes{
        padding: 12px 0;
        &.center{
            .checkbox{
                margin:5px 10px;
            }
        }
    }
    .benefit-banner{
        display:none;
    }
    .wrap-element{
        .overlay-content{
            .dop{
                td{
                    width:100%;
                    display: block;
                    &>div{
                       border-right: 0 !important;
                       .content-dop .b-btn{
                            margin-bottom:0px;
                        } 
                    } 
                }
            }
        }
    }
    .client-data .input{
        width:100%;
        input{
            padding:9px 3%;
            width:94%;
        }
    }
    .information-about-car{
        table{
            width:100%;
            td{
                width:100%;
                display: block;
                text-align: center;
                margin:0;
                margin-bottom:15px;
                &>span{
                    clear: both;
                    float:none;
                    width: 100%;
                }
            }
        }
    }
    .btns{
        .b-btn{
            width: 100%;
            padding: 7px 0;
        }
    }
    .spicial-other-pages{
        &>a{
            margin-right:0 !important;
            
        }
    }
    .price-content{
        .left-col{
            width: 100%;
        }
        .right-col{
            width: 100%;
            .calendar{
                .month{
                    width: 31%;
                    float: left!important;
                    margin-right: 2.3333%;
                    
                    
                }
            }
        }
    }
    .car-prices td.rowspan{
        text-align: center;
        .image{
            width:100%;
        }
    }
    .partners-list{
        &>div{
            width:49%;
        }
    }
    .contacts-line{
        width:100%;
    }
    .contacts-map{
        position: static;
        width: 92%;
        margin: 4% 4%;
        height:500px;
    }
    .wrap-table{
        table{
            width:99%;
        }
    }
    .calendar-slider{
        width:100%;
    }
    .white-overlay{
        .input, .inputs, .table-input, .textarea{
            width:100% !important;
        }
    }
    .navigation-tabs{
        width:100%;
        &>div{
            width:33.3333%;
        }
    }
    .insurance-tab{
        .dop-input{
            text-align: center;
            &>div{
                .b-btn{
                    display: block;
                    margin-left:0;
                    margin-top: 20px;
                }
            }
        }
    }
    .tab-content>div .overlay-content>div .input-50{
        width:100%;
        margin-right:0;
        margin-bottom:20px;
    }
    .white-overlay{
        .dop-input{
            text-align: center;
            input{
                display: block;
                width:40%;
                margin:7px auto;
            }
            span{
                display: block;
            }
            .b-btn{margin-top:8px; margin-left:0; width:100%; padding:7px 0;}
        }
    }
    .modal-inner{
        width:90% !important;
        padding:30px 5%;
        .inputs{
            .input {
                width:100%;
                clear: both;
            }
        }
    }
    .login-modal{
        width: 80%;
        position: relative;
        float: left;
        top: 0;
        left: 0;
        transform: inherit;
        margin: 150px 10%;
    }
    .login-and-register{
        float:left;
        position: relative;
        width:100%;
        min-height:100%;
    }
    .modal-wrap{
        .col-2-inputs{
            width:auto;
            .input{
                width:100%;
            }
        }
    }
    .fancybox-wrap{
        margin-top: 60px;
    }
    .two-btn{
        height: 60px;
        margin-top:30px;
        .b-btn{
            width: 100%;
            margin: 5px 0;
        }
    }
    .drop-down{
        .inner{
            width: 92%;
            margin: 0 4%;
            .left-col{
                width:100%;
            }
            .right-col{
                width:100%;
                margin-top:10px;
                &>div{
                    font-size: 13px;
                    padding: 7px 26px;
                }
            }
        }
    }
    #baby-chair .form-footer{
        .b-btn{
            display: block;
            width: 100%;
            padding: 6px 0;
            clear: both;
        }
        h3{
            margin-bottom:15px;
        }
    }
}
@media screen and(max-width:600px){
    .price-content{
        .right-col{
            .calendar{
                .month{
                    width: 48%;
                    float: left!important;
                    margin-right: 4%;
                    &:nth-child(3n+3){
                        margin-right:4%;
                    }
                    &:nth-child(2n+2){
                        margin-right:0;
                    }
                }
            }
        }
    }
    .person-discounts{
        &>div{
            width: 100%;
            margin-right:0 !important;
            &>div{
                height:200px;
            }
        }
    }
    .add-button{
        width:100%;
        padding:8px 0;
        margin-bottom:20px;
    }
    .input{
        &.not-full{
            width:100%;
        }
    }
    .under-table{
        width:100%;
        padding:8px 0;
    }
    .insurance-tab{
        .dop-input{
            input{
                display: block;
                width: 35%;
                padding: 9px 0;
                margin: 10px auto 10px auto!important;
            }
        }
        table.type-1{
            width:100%;
        }
    }
    .input.input-type-2{
        width:100%;
    }
    .modal-wrap{
        .text-modal{
            width:auto;
        }
    }
    .modal-wrap .modal{
        width:auto;
        h2{
            font-size:22px;
        }
    }
}
@media screen and(max-width:558px){
   .drop-down {
        top: 172px;
    } 
}
@media screen and(max-width:450px){
    .col-2{
        &>div{
            width:100%;
        }
    }
    .spicial{
        a{
            width:100% !important;
            height:300px;
        }
    }
    .manufacturer{
        clear: both;
        padding-bottom:7px;
    }
    .bottomline{
        text-align: center;
        *{
            display: inline-block;
            float:none !important;
        }
    }
    .footer-blocks{
        &>div{
            width: 100% !important;
            clear: both;
            .footer-menu{
                width:100%;
                li{
                    width:100%/2;
                }
            }
            &:nth-child(2),&:nth-child(3){
                width:100% !important;
                margin-top:10px;
                margin-bottom:10px;
                text-align: center !important;
                .wrapSocial{
                    float: none;
                    margin-right: 0 !important;
                    text-align: center !important;
                }
            }
        }
    }
    .owl-controls{
        display: none;
    }
    .filter-inputs{
        .floating-left{
            width: 100%;
            input.icon{
                padding-left: 10%;
                width: 85%;
            }
        }
    }
    .price-content{
        .right-col{
            .calendar{
                .month{
                    width: 100%;
                    float: left!important;
                    margin-right: 0 !important;
                }
            }
        }
    }
    .spicial-other-pages{
        display:none;
    }
    .client-data{
        .b-btn{
            width:100%;
            padding:8px 0;
        }
    }
    .dop-wrap{
        .dop-input{
            width:100%;
            .b-btn{
                clear: both;
                margin-top:10px;
                width:100%;
                padding:6px 0;
                margin:0;
            }
        }
    }
    .modal-wrap .history-info{ 
        .image{
            width:100%;
        }  
        .head-info{
            margin-left: 0;
        }
    }
    .fancybox-opened{
        margin-top:50px;
    }
    .drop-down {
        top: 313px;
    } 
}
@media screen and(max-width:340px){
    .header{
        .logo{
            display:none;
        }
        .b-btn{
            margin-left:0;
        }
    }
}
@media screen and(max-height:930px){
    .login-modal{
        max-height:80%; 
        overflow-y: auto;
        overflow-x:hidden;
        transform: translate(-50%,-50%);
        h2{
            position: relative;
            top:0;
            color:#333;
            margin-bottom:20px;
        }
    }
}